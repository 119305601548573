import { ReactNode } from "react";

import { Icon } from "@chakra-ui/icons";
import { FiBook } from "react-icons/fi";
import { IoIosSettings } from "react-icons/io";
import { IoHomeOutline } from "react-icons/io5";
import { PiGraduationCapFill } from "react-icons/pi";
import { RiFilePaper2Line } from "react-icons/ri";

import { AppConfig } from "../services/app-config-service";

export enum UserRole {
  User = "User",
  Admin = "Admin"
}

// const allRoles = [UserRole.User, UserRole.Admin];

export interface MenuLink {
  label: string;
  path: string;
  target?: string;
  icon?: ReactNode;
  userRoles: UserRole[];
}

export const TopMenuLinks: MenuLink[] = [
  // { label: "Team", path: "https://www/our-team", target: "_blank", userRoles: allRoles }
];

export let SideMenuLinks: MenuLink[] = [
  { label: "Home", path: "/", icon: <Icon as={IoHomeOutline} />, userRoles: [UserRole.User] },
  { label: "SOP Builder", path: "/sop-builder", icon: <Icon as={RiFilePaper2Line} />, userRoles: [UserRole.User] },
  { label: "Resources", path: "/resources", icon: <Icon as={FiBook} />, userRoles: [UserRole.User] },
  {
    label: "Courses",
    path: "https://up-or-on-the-rocks-hospitality.teachable.com/",
    icon: <Icon as={PiGraduationCapFill} />,
    target: "_blank",
    userRoles: [UserRole.User]
  },
  { label: "Admin", path: "/admin", icon: <Icon as={IoIosSettings} />, userRoles: [UserRole.Admin] }
];

// TODO: Remove this once we go live with profile-driven conversations
if (AppConfig.shouldHideWipFeatures) {
  SideMenuLinks = SideMenuLinks.filter((link) => link.label !== "Profile");
}
