import type { PathRouteProps } from "react-router-dom";

import AccountPage from "../pages/AccountPage";
import AdminPage from "../pages/admin/AdminPage";
import HandbookBuilderPage from "../pages/handbook-builder/HandbookBuilderPage";
import HomePage from "../pages/home/HomePage";
import JobDescriptionBuilderPage from "../pages/job-description-builder";
import PaymentSuccessPage from "../pages/PaymentSuccessPage";
import ResourcesPage from "../pages/resources/ResourcesPage";
// import SopBuilderPage from "../pages/sop-builder/SopBuilderPage";

interface Props extends PathRouteProps {
  bodyPadding?: number[];
  bgColor?: string;
  noSubscriptionNeeded?: boolean;
}

// All routes require authentication, subscription required by default unless noSubscriptionNeeded is true
export const routes: Array<Props> = [
  {
    path: "/",
    element: <HomePage />,
    bodyPadding: [0, 0],
    noSubscriptionNeeded: true
  },
  {
    path: "/payment-success",
    element: <PaymentSuccessPage />,
    noSubscriptionNeeded: true
  },
  {
    path: "/account",
    element: <AccountPage />,
    noSubscriptionNeeded: true
  },
  {
    path: "/job-builder",
    element: <JobDescriptionBuilderPage />
  },
  {
    path: "/job-builder/:id",
    element: <JobDescriptionBuilderPage />
  },
  {
    path: "/admin",
    element: <AdminPage />
  },
  {
    path: "/resources",
    element: <ResourcesPage />
  },
  {
    path: "/handbook-builder",
    element: <HandbookBuilderPage />
  }
];
