import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import { axiosClient } from "../services/httpclient-service";

export const usePurchaseMutation = () => {
  const toast = useToast();

  return useMutation({
    mutationFn: async () => {
      const response = await axiosClient.post("/get-payment-link");
      return response.data;
    },
    onError: (error) => {
      console.error("Error:", error);
      toast({
        title: "Error",
        description: "Failed to get payment link. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true
      });
    },
    onSuccess: (data) => {
      if (data.error) {
        toast({
          title: "Error",
          description: data.error,
          status: "error",
          duration: 5000,
          isClosable: true
        });
        return;
      }

      if (data.url) {
        window.location.href = data.url;
      }
    }
  });
};
