import { VStack, Text, HStack, Icon, Box } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface MarketingFeatureCardProps {
  title: string;
  description: string;
  icon: IconType;
  benefits: string[];
}

export const MarketingFeatureCard = ({ title, description, icon, benefits }: MarketingFeatureCardProps) => {
  return (
    <VStack
      w="full"
      spacing={4}
      p={6}
      bg="white"
      borderRadius="xl"
      align="flex-start"
      h="full"
      border="solid"
      borderWidth={2}
      borderColor="brand.100"
      _hover={{ borderColor: "brand.500", transform: "translateY(-2px)" }}
      transition="all 0.2s"
      shadow="md"
    >
      <HStack spacing={3}>
        <Box
          p={2}
          borderRadius="lg"
          bg="brand.50"
          color="brand.500"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Icon as={icon} boxSize={6} />
        </Box>
        <Text fontSize="xl" fontWeight="bold" color="gray.800">
          {title}
        </Text>
      </HStack>

      <Text color="gray.600" fontSize="md">
        {description}
      </Text>

      <VStack align="flex-start" spacing={2} pt={2}>
        {benefits.map((benefit, index) => (
          <HStack key={index} spacing={2} color="gray.700">
            <Box w={1} h={1} borderRadius="full" bg="brand.500" />
            <Text fontSize="sm">{benefit}</Text>
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
}; 