export const PREDEFINED_EQUAL_OPPORTUNITY_TEXT = `
**Policy Statement**  
Our company is committed to maintaining a work environment that embraces diversity and where employment decisions are based on merit, qualifications, and abilities. We are an Equal Opportunity Employer and do not discriminate in hiring, promotion, or any other aspect of employment on the basis of race, color, religion, gender, national origin, age, disability, sexual orientation, gender identity or expression, marital status, pregnancy, veteran status, or any other characteristic protected by law.

**Scope**  
This policy applies to all employees and applicants for employment and covers all terms, conditions, and privileges of employment, including recruitment, hiring, placement, compensation, promotion, discipline, and termination.

**Objective**  
The objective of this policy is to ensure that all employment practices are conducted without discrimination and to promote a diverse and inclusive workplace.`;

export const PREDEFINED_AT_WILL_TEXT = `Employment with our company is "at-will." This means that either you or the company may terminate the employment relationship at any time, with or without notice, and with or without cause. No representative of the company has the authority to enter into any agreement contrary to the foregoing "at-will" relationship except for the company president, and even then, any such agreement must be in writing.`;

export const PREDEFINED_HARASSMENT_TEXT = `
**Purpose**  
Our company is committed to creating and maintaining a work environment where all individuals are treated with dignity and respect. We aim to ensure a professional atmosphere characterized by mutual trust and the absence of intimidation, oppression, and exploitation. The prevention and correction of harassment and discrimination are essential to our mission and will not be tolerated.

**Policy Statement**  
We strictly prohibit any form of unlawful discrimination or harassment based on race, color, religion, sex, national origin, ancestry, citizenship status, age, disability, genetic information, marital status, veteran status, gender identity or expression, sexual orientation, or any other characteristic protected under federal, state, or local laws. This policy applies to all personnel actions including hiring, promotion, termination, and working conditions, and covers employees, applicants, interns, volunteers, and contractors.

Prohibited Conduct Includes, But is Not Limited To:

- Verbal harassment such as jokes, slurs, and other derogatory remarks about an individual's protected characteristics.
- Physical harassment including unwelcome touching or obstructive gestures.
- Visual harassment such as displaying derogatory or sexually suggestive images.
- Electronic communication including emails or messages that contain offensive content based on protected characteristics.
- Sexual harassment, which includes unwelcome sexual advances, requests for sexual favors, and other verbal or physical conduct of a sexual nature. This extends to offering employment benefits in exchange for sexual favors, or making employment decisions based on an individual's acceptance or rejection of sexual advances.

**Reporting and Complaint Procedure**  
If you experience or witness any form of discrimination or harassment, report it immediately to your supervisor, Human Resources, or any member of management with whom you feel comfortable. We encourage prompt reporting as it enables us to address and resolve issues swiftly. All complaints will be investigated confidentially to the fullest extent possible, and appropriate corrective actions will be taken, including disciplinary measures up to termination.

**No Retaliation**  
Retaliation against any employee who reports discrimination or harassment, or participates in an investigation, is strictly prohibited and will itself be subject to disciplinary action.

**Training and Acknowledgment**  
All employees are required to participate in training sessions on recognizing and preventing workplace harassment and discrimination. Employees must acknowledge that they have read and understood this policy, and agree to comply with its provisions as a condition of continued employment.

This comprehensive policy ensures all employees are aware of their rights and responsibilities regarding workplace behavior, emphasizing our commitment to a respectful and professional work environment.`;

export const getClassificationText = (fteHours: number) =>
  `Employees at our restaurant are classified as follows:

- **Full-Time Employees:** Those who work ${fteHours} or more hours per week.
- **Part-Time Employees:** Those who work fewer than ${fteHours} hours per week.
- **Exempt Employees:** Employees who are exempt from overtime provisions under the FLSA. These employees do not receive overtime pay as their roles primarily involve executive, administrative, or professional duties as defined by the FLSA.
- **Nonexempt Employees:** Employees who are entitled to overtime pay under the FLSA. Overtime is paid at one and a half times the regular rate for hours worked beyond 40 in a workweek.

**Notification of Classification**  
At the time of hire or any change in position, employees will be informed of their employment classification. Any questions regarding classification should be directed to Human Resources.

**Salary and Deductions for Exempt Employees**  
The company adheres to the salary basis requirements of the FLSA and does not make improper deductions from the salaries of exempt employees. Permitted deductions may include:

- Deductions required by law (e.g., income taxes).
- Voluntary deductions for employee benefits.
- Absences of one or more full days for personal reasons, or due to sickness or disability if the deduction complies with a bona fide plan or policy.
- Penalties imposed for infractions of safety rules of major significance.
- Unpaid disciplinary suspensions for workplace conduct violations.
- Any full workweek in which the exempt employee performs no work.

If an exempt employee believes an improper deduction has been made from their pay, they should report this immediately to the Payroll department. The issue will be investigated promptly, and any improper deductions will be reimbursed.

**Compensation During FMLA and Partial Workweeks**  
Exempt employees will only be compensated for actual hours worked during the initial or final week of employment, and when taking unpaid leave under the FMLA.

**Overtime Pay for Nonexempt Employees**  
Nonexempt employees will receive overtime pay for hours worked over 40 in a workweek at a rate of one and a half times their regular hourly rate.

This policy ensures all employees understand their rights and obligations regarding compensation and classification, fostering transparency and compliance within our workplace.`;

export const PREDEFINED_TIP_CREDIT_TEXT = `
**Tip Reporting**  
All tipped employees are required to report all tips received in any form.

**Tip Credit Explanation**  
Under the FLSA and Michigan state law, the restaurant is allowed to pay tipped employees a lower hourly wage than the standard minimum wage, compensating for this difference through the tips earned by the employee. This wage arrangement is known as the "tip credit." The tip credit will not exceed the amount of tips received.

**Wage Calculation**  
Tipped employees will be paid the tipped minimum wage as stipulated by Michigan law. The combination of the tipped minimum wage and the tip credit will at least equal the state's regular minimum wage. If an employee's tips plus the tipped minimum wage do not reach the regular minimum wage, the restaurant will compensate the difference to ensure the employee receives the full minimum wage.

**Hourly Rate Notification**  
Employees will be informed of their specific hourly pay rate upon starting their employment and will be notified of any changes to this rate. Pay rates will also be displayed on pay stubs.

**Tip Pooling**  
Employees may retain all of their tips, except in cases where tip pooling is practiced. Tip pooling is legal and involves sharing tips among a group of employees, and the specifics of any tip pooling arrangement will be clearly communicated to all involved employees.

**Compliance**  
Employees are encouraged to approach management with any questions or concerns regarding the tip credit system or their wages. The restaurant is committed to transparency in wage calculations and adherence to all applicable laws.`;

export const PREDEFINED_PAYROLL_DEDUCTIONS_TEXT = `
**Overview**  
This policy outlines the deductions from employee paychecks and procedures for addressing payroll discrepancies, ensuring compliance with the Fair Labor Standards Act (FLSA).

**Mandatory and Voluntary Deductions**  
- Mandatory Deductions: Include federal and state taxes, Social Security, and Medicare, based on employee withholding forms.
- Voluntary Deductions: Cover benefits such as health insurance and retirement accounts, elected by the employee.

**Deductions for Exempt Employees**  
Exempt employees, not eligible for overtime, may have salary deductions under these conditions:

- Full-day absences for personal, sickness, or disability reasons aligned with company policies.
- Disciplinary suspensions for significant safety or conduct violations.
- Proportional deductions for actual time worked during the initial and final employment weeks.
- Full work-weeks with no work performed.

**Correcting Payroll Errors**  
Employees should review their paychecks for errors and report discrepancies to Human Resources immediately. The company commits to promptly investigating and correcting any verified errors by the next pay period, including reimbursing improper deductions.

**Exempt Employee Specifics**  
Exempt employees should report suspected improper deductions to Payroll. After investigation, confirmed improper deductions will be reimbursed.

**Compliance and Reporting**  
For questions or changes to withholding, contact Human Resources. This policy ensures employees are well-informed about their payroll rights and responsibilities.`;

export const PREDEFINED_PERSONAL_HYGIENE_TEXT = `
**Purpose**  
This policy outlines the personal hygiene standards required for all employees to ensure the safety and quality of our food products, in compliance with applicable food codes and health regulations.

**Personal Cleanliness**  
1. **Hand Washing:** Employees must wash their hands thoroughly with soap and water for at least 20 seconds:
	- Before starting work.
	- After using the restroom.
	- Before and after handling food or food contact surfaces.
	- After any activity that contaminates the hands.
2. **Fingernails:** Fingernails must be kept clean and trimmed. No artificial nails or nail polish is allowed when handling food.

**Appropriate Attire**  
1. **Uniforms:** Employees are required to wear clean uniforms or clothing at the start of each shift. Clothing must be practical, clean, and worn in a way that covers body hair and personal garments.
2. **Hair Restraint:** All employees must use hair restraints such as hairnets, hats, or headbands to keep hair away from food and food contact surfaces.
3. **Jewelry:** No jewelry, except for a plain band ring, may be worn on the hands or arms while working in food preparation areas.

**Health Status**  
1. **Illness Reporting:** Employees must report to management any health conditions, including symptoms of foodborne illnesses such as vomiting, diarrhea, jaundice, or sore throat with fever.
2. **Exclusion from Work:** Employees showing signs of contagious illnesses will be excluded from work duties involving direct food handling until cleared by a healthcare professional.

**Maintaining Work Areas**  
1. **Cleanliness:** Employees are responsible for keeping their work areas and equipment clean and sanitized according to the restaurant's cleaning schedules and procedures.
2. **Waste Disposal:** Proper disposal of waste materials and other disposables must be performed regularly to maintain sanitary conditions.

**Training and Compliance**   
All employees will receive training on this personal hygiene policy as part of their orientation and periodically thereafter. Compliance with these guidelines is mandatory for all staff to reduce the risk of foodborne illnesses.`;

export const getDrugTestingText = (drugTests: string[]) => {
  const bulletPoints = [];

  if (drugTests.includes("Pre-employment")) {
    bulletPoints.push("- **Pre-Employment:** All job candidates must pass a drug test before beginning employment.");
  }

  if (drugTests.includes("Random")) {
    bulletPoints.push(
      "- **Random Testing:** Employees may be selected at random for drug testing at any time without prior notice."
    );
  }

  if (drugTests.includes("Post Accident")) {
    bulletPoints.push(
      "- **Post-Accident:** Employees involved in workplace accidents or incidents that cause injury or property damage will be tested to determine if drugs were a factor."
    );
  }

  if (drugTests.includes("Reasonable Suspicion")) {
    bulletPoints.push(
      "- **Reasonable Suspicion:** Employees displaying behaviors suggestive of drug use, such as erratic behavior or decreased work performance, may be tested."
    );
  }

  if (drugTests.includes("Return to work")) {
    bulletPoints.push(
      "- **Return-to-Work:** Employees returning from a leave related to substance abuse treatment may be tested before resuming work duties."
    );
  }

  return `**Policy Overview**  
As part of our commitment to maintaining a safe and drug-free workplace, all employees are subject to mandatory drug testing under the following circumstances:

${bulletPoints.join("\n")}

**Testing Procedures**  
Drug testing will be conducted through a professional and certified laboratory using standardized procedures to ensure fairness and accuracy. Employees will be notified of the testing requirement and must cooperate fully with all aspects of the testing process.

**Confidentiality**  
Results from drug tests are considered confidential and will be handled with the utmost respect for privacy and discretion. Information will be disclosed only to those who have a legitimate need to know in compliance with relevant laws and regulations.

**Consequences of Positive Tests or Refusal to Submit to Testing**  
A positive drug test or refusal to submit to a drug test is a violation of company policy and may result in disciplinary action, up to and including termination of employment.

**Rights and Responsibilities**  
Employees have the right to appeal a positive test result and may request a retest at their expense. It is the responsibility of every employee to comply with this policy, and refusal to comply may result in disciplinary action.`;
};

export const SUBSTANCE_ABUSE_POLICY_TEXT = `This policy outlines our commitment to maintaining a safe, healthy, and productive work environment free from the effects of drugs and alcohol. Compliance with this policy is mandatory for all employees to ensure the safety of both staff and guests.

**Prohibited Conduct**  
1. Illegal Drugs: The use, possession, solicitation, sale, or distribution of illegal drugs on company property or while performing company business is strictly prohibited.
2. Alcohol: The consumption of alcohol during work hours, including meal and break periods, is strictly prohibited, except where alcohol is provided by the company at sanctioned events. Even in these circumstances, moderation is expected.
3. Impairment at Work: Arriving at work under the influence of any illegal drug or alcohol is prohibited and will result in immediate disciplinary action, up to and including termination of employment.

**Consequences of Policy Violation**  
Violation of this drug and alcohol policy will result in disciplinary action, up to and including termination. The severity of the consequences may depend on the circumstances and any history of previous violations.`;

export const WEAPONS_AND_VIOLENCE_POLICY_TEXT = `
**Policy Statement**  
Our restaurant is committed to maintaining a safe and secure environment for all employees, customers, and visitors. To ensure safety, the possession of weapons and any act of violence are strictly prohibited on company premises.

**Prohibited Conduct**  
1. **Weapons:** No employee, customer, or visitor is permitted to carry, possess, or use any type of weapon on company property or while conducting company business, regardless of whether the individual has a legal permit. This includes but is not limited to firearms, knives exceeding a reasonable size for culinary use, explosives, and other items that could be used to inflict harm.
2. **Violence:** Any act of violence, threats of violence, or behavior perceived as threatening or aggressive are prohibited. This includes physical attacks, threatening language, or menacing behavior.

**Prevention and Awareness**  
- Employees are encouraged to be vigilant and report any suspicious or aggressive behavior immediately to management.
- The company will provide training on conflict resolution and how to handle workplace violence.

**Reporting Procedures**  
- Any incidents involving weapons or acts of violence must be reported immediately to a supervisor, manager, or directly to Human Resources.
- Reports can be made confidentially, and all reported incidents will be investigated promptly and thoroughly.

**Response to Violations**  
- Violations of this policy will be taken seriously and may result in disciplinary action, up to and including termination of employment and legal action, depending on the severity of the incident.
- In cases where criminal actions are suspected, local law enforcement will be involved.

**Employee Responsibility**  
- All employees are responsible for adhering to this policy and contributing to a safe work environment. Cooperation in maintaining a violence-free workplace is expected of everyone.

This policy is integral to ensuring that our restaurant remains a safe and welcoming place for everyone, reinforcing our commitment to a secure and peaceful work environment.`;

export const DISCIPLINE_POLICY_TEXT = `
1. If someone does not follow the required procedure, they will get a verbal reminder and written documentation will go into their employee file. Extra training on the approved procedure and its importance will be provided.
2. If it happens again a written warning to be signed will be administered to be placed into their file. Extra training on the approved procedure and why it is important will be provided.
3. A third infraction will result in written documentation up to and including termination.`;

export const CONFLICT_OF_INTEREST_POLICY_TEXT = `
**Definition of Conflict of Interest**  
A conflict of interest occurs when personal interests or external commitments interfere, or appear to interfere, with the company's interests. This can be financial, personal, or related to other employment.

**Examples Include**  
- Competing directly with the company.
- Owning interests in a business dealing with the company.
- Using company resources for personal gain.
- Accepting gifts from entities doing business with the company that might influence decisions.

**Employee Responsibilities**  
- Disclose any potential conflicts to supervisors or Human Resources immediately.
- Maintain transparency about relationships that could constitute conflicts of interest.

**Management Responsibilities**  
- Evaluate and act on disclosed conflicts to mitigate impact on the company.
- Provide guidance on identifying and avoiding conflicts of interest.

**Resolution of Conflicts**  
- The company will resolve conflicts by reassigning duties or terminating the conflicting relationship if necessary.
- Decisions will prioritize the company's best interest.`;

export const ELECTRONIC_SIGNATURE_POLICY_TEXT = `
**Scope and Use**  
Electronic signatures can be utilized for both internal and external documents such as contracts and approvals, where legally acceptable.

**Authorization and Security**  
Only authorized employees with rights granted by the IT department can use electronic signatures. Each employee must maintain the confidentiality and security of their electronic signature credentials.

**Verification and Compliance**  
Employees must use electronic signatures in line with company policies and legal standards, supported by tools and training provided by the IT department.

**Record Keeping**  
Electronically signed documents must be logged and securely stored in the company's document management system for auditability and compliance.

**Policy Violation**  
Improper use of electronic signatures, including unauthorized use or credential sharing, may lead to disciplinary actions, including termination.`;

export const PERSONAL_FILES_AND_SSN_MANAGEMENT_TEXT = `
**Personal Files Management**  
1. **Confidentiality**: Employee personal files, which may include personal data, employment history, payroll records, and other sensitive information, are considered confidential.
2. **Access Rights**: Access to personal files is strictly limited to authorized HR personnel and management staff who require this information to perform their job duties. Employees may request to view their own personal files by scheduling a meeting with HR.
3. **Storage and Security**: All personal files are securely stored in locked cabinets or secure electronic systems with access controls. Electronic files are protected with encryption and password safeguards.

**Social Security Number (SSN) Protection**  
1. **Collection and Use**: The collection of SSNs will be limited to lawful purposes, such as payroll, benefits administration, and reporting to government bodies. SSNs will not be used as employee identifiers except for purposes required by law.
2. **Disclosure**: SSNs will not be disclosed to third parties without the consent of the individual, except where required by law. Any disclosure of SSNs within the company will be on a strict need-to-know basis.

**Disposal**  
Documents containing SSNs will be disposed of securely through methods such as shredding or electronic erasure to prevent data theft or misuse.`;

export const TECH_INTERNET_SOCIAL_MEDIA_POLICY_TEXT = `
**Scope**  
This policy applies to all employees who use company-provided technology, access the internet on company premises, or engage on social media in relation to their employment.

**Technology and Internet Use**  
1. Acceptable Use: Employees are permitted to use company technology and internet access for job-related activities. Limited, reasonable personal use is allowed provided it does not interfere with work duties or consume significant resources.
2. Prohibited Use: Employees are prohibited from visiting inappropriate websites, downloading unauthorized software or media, and engaging in illegal activities using company resources.
3. Security: Employees must secure their devices with passwords and should not share these with others. All company devices must be protected by up-to-date antivirus software and security patches.

**Social Media**  
1. Professional Conduct: Employees must conduct themselves professionally on social media, especially when representing or discussing the restaurant. Confidential or proprietary information must not be shared.
2. Personal Accounts: Employees should clarify that their posts are personal if they identify themselves as restaurant employees. Content that could harm the restaurant's reputation or that of its employees is strongly discouraged.
3. Workplace Usage: While using social media at work, employees should ensure that it does not distract from their duties or disrupt the workplace environment. Social media use should not interfere with customer service or the performance of daily tasks.
4. Interaction with Media: Employees are not permitted to speak to the media on behalf of the restaurant or share sensitive information without authorization.

**Right to Monitor**  
The restaurant reserves the right to monitor all activities on company-provided technology and internet access. This monitoring is intended to ensure compliance with this policy and to maintain the security of business information.

**Enforcement**  
Violations of this policy may result in disciplinary action, up to and including termination. Employees are encouraged to report any suspected breaches of this policy to their supervisor or Human Resources immediately.`;

export const COMPANY_VEHICLE_USE_POLICY_TEXT = `
**Eligibility and Authorization**  
Only employees who have been specifically authorized by management and have a valid driver's license may operate company vehicles. Eligibility is contingent upon a satisfactory driving record and may require periodic driving record checks.

**Vehicle Use**  
Company vehicles are to be used solely for business purposes. Personal use of company vehicles is strictly prohibited unless specifically authorized by management. All trips must be logged, including mileage, purpose, and duration of use.

**Driver Responsibilities**  
Drivers must:
- Operate vehicles in a safe and legal manner at all times.
- Ensure that the vehicle is locked and secure when unattended.
- Maintain a clean and tidy vehicle interior.
- Report any mechanical issues or maintenance needs immediately to the designated manager.
- Ensure compliance with all traffic laws and regulations.

**Accidents and Traffic Violations**  
All traffic violations or accidents occurring while operating a company vehicle must be reported to management immediately, regardless of severity. A detailed report of the incident, including the circumstances and any police reports, must be submitted within 24 hours of the incident.

**Alcohol and Drug Policy**  
Operating company vehicles under the influence of alcohol or drugs is prohibited and grounds for immediate termination. Employees may be subject to random drug and alcohol testing following company policy and applicable law.

**Vehicle Maintenance and Inspections**  
Regular maintenance checks are scheduled for all company vehicles. The driver is responsible for ensuring that the vehicle is in good working condition before use. Pre-trip inspections must be completed and documented.

**Consequences of Policy Violation**  
Failure to comply with this vehicle use policy can result in disciplinary action, up to and including termination of employment. Employees may also be held personally liable for any damages resulting from unauthorized use.`;

export const EMPLOYEE_VEHICLE_USE_POLICY_TEXT = `
**Eligibility and Authorization**  
Employees must receive prior authorization from management to use their personal vehicle for business purposes. Authorization is contingent upon submission and verification of a valid driver's license, personal auto insurance that meets or exceeds company-required coverage limits, and a satisfactory driving record.

**Insurance Requirements**  
Employees using their personal vehicles for company business must carry auto insurance that complies with state laws and meets the minimum insurance coverage amounts specified by the company. Proof of insurance must be provided annually or upon request.

**Reimbursement**  
Employees will be reimbursed for approved business mileage at the current IRS standard mileage rate. To receive reimbursement, employees must submit a mileage log that includes dates, mileage, and business purpose of each trip.

**Driver Responsibilities**  
While operating a personal vehicle for business purposes, employees are expected to:

- Drive safely and abide by all traffic laws.
- Maintain their vehicle in good working condition to ensure safe operation.
- Report any accidents involving their personal vehicle while on company business to their manager immediately.

**Accidents and Liability**  
In the event of an accident while using a personal vehicle for business purposes, the employee's personal auto insurance will be considered the primary insurance coverage. Employees must report any accidents to their personal insurance provider and to the company within 24 hours.

**Prohibited Use**  
The use of personal vehicles for company business is limited strictly to job-related activities. Personal errands or the transport of non-employees without prior approval is prohibited.

**Alcohol and Drug Policy**  
Operating a vehicle for company business under the influence of alcohol or drugs is strictly prohibited and will result in disciplinary action, up to and including termination of employment.`;

export const COMPANY_PROPERTY_AND_PROPERTY_DAMAGE_POLICY_TEXT = `
**Policy Overview**  
This policy outlines appropriate use and care of company property and details responsibilities for preventing and reporting property damage.

**Acceptable Use of Company Property**  
1. **Company Property**: Includes equipment, vehicles, supplies, and electronic devices.
2. **Authorized Use**: Company property should be used solely for business purposes. Employees must use property responsibly and follow all safety standards.
3. **Care and Custody**: Employees must handle company property properly, ensuring it is secure and maintained according to company guidelines.

**Prohibited Use**  
1. Personal Use: Unauthorized personal use of company property is prohibited. Limited personal use may be allowed if it is reasonable and approved by management.
2. Misuse and Negligence: Deliberate misuse or negligent handling of company property leading to damage is strictly prohibited.

**Reporting Damage**  
1. Accidental Damage: Report any accidental damage to company property immediately to a supervisor or the Facilities Management Department.
2. Intentional Damage or Theft: Immediate reporting of intentional damage, vandalism, or theft is required, with such actions subject to disciplinary measures and potential legal action.

**Employee Responsibilities**  
- Maintain company property in good condition.
- Return all company property in proper condition upon termination or reassignment.

**Enforcement and Consequences**  
Non-compliance with this policy may result in disciplinary actions including termination and potential restitution for damages.`;

export const OSHA_POLICY_TEXT = `
**Policy Overview**  
We are committed to adhering to all Occupational Safety and Health Administration (OSHA) regulations to ensure a safe working environment for all staff. Compliance with OSHA standards is essential for our operational success.

**Employee Safety Responsibilities**  
1. **Compliance**: Employees must adhere to all safety protocols and practices.
2. **Reporting**: Immediately report workplace injuries, illnesses, or unsafe conditions to a supervisor.

**Employer Commitments**  
- **Hazard Prevention**: We will perform risk assessments and implement preventive measures regularly.
- **Safety Equipment**: We will provide and maintain necessary personal protective equipment (PPE) and safety tools.
- **Emergency Procedures**: Emergency handling procedures will be clearly communicated to all employees.

**Enforcement and Discipline**  
Non-compliance with safety standards may lead to disciplinary actions, including termination.`;

export const ALCOHOL_POLICY_TEXT = `
**Legal Age Requirement**  
Employees must check a valid ID to verify that customers are of legal drinking age before serving alcohol.

**Training and Certification**  
Employees serving alcohol must complete a certified training program and keep their certification on record.

**Refusal of Service**  
Employees should refuse service to intoxicated or aggressive customers and immediately inform a manager.

**Legal Consequences**  
Serving alcohol to underage or visibly intoxicated persons may result in penalties, including fines and potential loss of the liquor license.`;

export const FMLA_POLICY_TEXT = `
**Overview**  
The Family and Medical Leave Act (FMLA) provides eligible employees with up to 12 workweeks of unpaid, job-protected leave per year for specified family and medical reasons, or for any qualifying exigency arising out of the fact that a covered military member is on active duty. Eligible employees may also take up to 26 workweeks of leave in a single 12-month period to care for a covered servicemember with a serious injury or illness.

**Employee Eligibility**  
To be eligible for FMLA leave, an employee must:
- Have worked for the company for at least 12 months
- Have worked at least 1,250 hours during the 12-month period immediately preceding the leave
- Work at a location where the company employs 50 or more employees within 75 miles

**Qualifying Reasons for Leave**  
Eligible employees may take FMLA leave for:
- The birth of a child and to bond with the newborn within one year of birth
- The placement of a child for adoption or foster care and to bond with the newly placed child within one year of placement
- A serious health condition that makes the employee unable to perform the functions of their job
- To care for the employee's spouse, child, or parent who has a serious health condition
- Any qualifying exigency arising out of the fact that the employee's spouse, son, daughter, or parent is a military member on covered active duty

**Notice Requirements**  
- Employees must provide 30 days advance notice of the need to take FMLA leave when the need is foreseeable
- When 30 days' notice is not possible, employees must provide notice as soon as practicable
- Employees must provide sufficient information for the employer to determine if the leave may qualify for FMLA protection

**Medical Certification**  
- The company may require medical certification supporting the need for leave due to a serious health condition
- The company may require second or third medical opinions (at the company's expense)
- Documentation confirming family relationship, adoption, or foster care placement may be required

**Job and Benefits Protection**  
During FMLA leave:
- The company will maintain the employee's health coverage under any group health plan
- Upon return from FMLA leave, most employees must be restored to their original or equivalent positions with equivalent pay, benefits, and other employment terms
- The use of FMLA leave cannot result in the loss of any employment benefit that accrued prior to the start of leave

**Use of Leave**  
- Leave can be taken intermittently or on a reduced leave schedule when medically necessary
- Employees must make reasonable efforts to schedule leave for planned medical treatment so as not to unduly disrupt operations
- The company may temporarily transfer an employee using intermittent leave to an alternative position that better accommodates recurring periods of leave

**Employee Responsibilities**  
- Provide sufficient information to determine if the requested leave may qualify for FMLA protection
- Inform the company if the requested leave is for a reason for which FMLA leave was previously taken or certified
- Provide medical certification if requested
- Report periodically on their status and intent to return to work

**Employer Responsibilities**  
- Inform employees requesting leave whether they are eligible under FMLA
- Specify any additional information required and the employee's rights and responsibilities
- Notify employees if leave will be designated as FMLA-protected
- Notify employees of the amount of leave counted against their FMLA entitlement

**Enforcement**  
FMLA makes it unlawful for any employer to:
- Interfere with, restrain, or deny the exercise of any right provided under FMLA
- Discharge or discriminate against any person for opposing any practice made unlawful by FMLA
- Discharge or discriminate against any person for involvement in any proceeding under FMLA

This policy complies with all federal FMLA requirements and any applicable state laws.
`;

export const PREDEFINED_ACKNOWLEDGMENT_TEXT = `
**Acknowledgment of Receipt and Understanding**

I hereby acknowledge that I have received and accessed a copy of the Employee Handbook. I understand that this Handbook provides essential information regarding the company's general personnel policies, employment practices, professional standards, and my responsibilities as an employee. I agree to read, understand, and adhere to the policies and procedures outlined within the Handbook.

I acknowledge that the Handbook serves as a guide and does not constitute an employment contract. The contents of the Handbook are subject to interpretation and can be modified, rescinded, or supplemented by the company at any time without prior notice. I understand that all changes to the Handbook will be communicated to me through appropriate channels such as in-person meetings or email.

This Handbook does not alter the at-will nature of my employment, which means that either I or the company may terminate the employment relationship at any time, with or without cause or notice. No manager or representative of the company, other than the President via a signed written contract, has the authority to enter into any agreement contrary to the foregoing.

I understand that it is my responsibility to familiarize myself with the contents of the Handbook and to seek clarification from Human Resources if I have any questions about its content or how it applies to my employment.

By signing this acknowledgment, I accept that the Handbook supersedes any prior versions and any conflicting or outdated policies that have been revoked and are null and void.

[Employee Signature] _______________________________________________________________ [Date] __________

This acknowledgment confirms my comprehension of the terms as an at-will employee and my commitment to adhere to the current policies and procedures set forth by the company.`;
