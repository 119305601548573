import { useQuery } from "@tanstack/react-query";

export interface Resource {
  id: number;
  display_name: string;
  description: string;
  stored_file_name: string;
  category: string;
  file_type: string;
  status: string;
}

async function getResources(): Promise<Resource[]> {
  const response = await fetch("/api/files");
  if (!response.ok) {
    throw new Error("Failed to fetch resources");
  }
  return response.json();
}

export function useResources() {
  return useQuery({
    queryKey: ["resources"],
    queryFn: getResources
  });
}
