import { bold, h1, h2, h3, paragraph } from "./markdownHelpers";
import {
  PREDEFINED_EQUAL_OPPORTUNITY_TEXT,
  PREDEFINED_AT_WILL_TEXT,
  PREDEFINED_HARASSMENT_TEXT,
  getClassificationText,
  PREDEFINED_TIP_CREDIT_TEXT,
  PREDEFINED_PAYROLL_DEDUCTIONS_TEXT,
  PREDEFINED_PERSONAL_HYGIENE_TEXT,
  getDrugTestingText,
  SUBSTANCE_ABUSE_POLICY_TEXT,
  WEAPONS_AND_VIOLENCE_POLICY_TEXT,
  DISCIPLINE_POLICY_TEXT,
  CONFLICT_OF_INTEREST_POLICY_TEXT,
  ELECTRONIC_SIGNATURE_POLICY_TEXT,
  PERSONAL_FILES_AND_SSN_MANAGEMENT_TEXT,
  TECH_INTERNET_SOCIAL_MEDIA_POLICY_TEXT,
  COMPANY_PROPERTY_AND_PROPERTY_DAMAGE_POLICY_TEXT,
  COMPANY_VEHICLE_USE_POLICY_TEXT,
  EMPLOYEE_VEHICLE_USE_POLICY_TEXT,
  OSHA_POLICY_TEXT,
  ALCOHOL_POLICY_TEXT,
  PREDEFINED_ACKNOWLEDGMENT_TEXT,
  FMLA_POLICY_TEXT
} from "./predefined-content";
import { HandbookFormData } from "./types";

export const generateMarkdownContent = (data: HandbookFormData): string => {
  let markdown = "";

  markdown += h1(data["handbook-title"] || "Employee Handbook");

  // Company Introduction Section
  markdown += h2("Company Introduction");
  if (data["company-culture-q"]) {
    markdown += h3("Culture and Mission");
    markdown += paragraph(data["company-culture-q"]);
  }

  // Employer-Employee Relationship Section
  markdown += h2("Employer-Employee Relationship");
  markdown += h3("Equal Opportunity Employer");
  markdown += paragraph(PREDEFINED_EQUAL_OPPORTUNITY_TEXT);
  markdown += h3("At-Will Employment");
  markdown += paragraph(PREDEFINED_AT_WILL_TEXT);
  markdown += h3("Harassment");
  markdown += paragraph(PREDEFINED_HARASSMENT_TEXT);
  markdown += h3("Employee Classification");
  markdown += paragraph(getClassificationText(data["fte-hours-q"] || 40));

  // Pay Policies Section
  markdown += h2("Pay Policies");
  if (data["pay-schedule-q"]) {
    markdown += h3("Pay Schedule");
    markdown += paragraph(data["pay-schedule-q"]);
  }
  if (data["tip-credit-q"] !== undefined) {
    markdown += h3("Tip Credit Policy");
    if (data["tip-credit-q"]) {
      markdown += paragraph(PREDEFINED_TIP_CREDIT_TEXT);
    } else {
      markdown += paragraph("We do not use tip credit to subsidize wages.");
    }
  }
  if (data["payment-method-q"]) {
    markdown += h3("Payment Method");
    markdown += paragraph(`Employees are paid via ${data["payment-method-q"]}.`);
  }
  markdown += h3("Payroll Deductions");
  markdown += paragraph(PREDEFINED_PAYROLL_DEDUCTIONS_TEXT);

  // Employee Benefits Section
  markdown += h2("Employee Benefits");

  if (data["offers-pto-q"] !== undefined) {
    markdown += h3("Paid Time Off (PTO)");
    if (data["offers-pto-q"]) {
      markdown += paragraph("We offer paid time off (PTO) to all employees based on the following policy:");
      if (data["pto-allocation-q"]) {
        markdown += paragraph(data["pto-allocation-q"]);
      }
      if (data["pto-rollover-q"]) {
        markdown += h3("PTO Rollover Policy");
        markdown += paragraph(data["pto-rollover-q"]);
      }
    } else {
      markdown += paragraph("We do not currently offer paid time off (PTO).");
    }
  }

  // Health Insurance Section
  if (data["health-insurance-policy"]) {
    markdown += h3("Health Insurance");
    markdown += paragraph(data["health-insurance-policy"]);

    const offersInsurance =
      data["health-insurance-policy"] && !data["health-insurance-policy"].includes("not be offering");
    if (offersInsurance) {
      if (data["insurance-enrollment-dates"]) {
        markdown += h3("Open Enrollment Period");
        markdown += paragraph(`Open enrollment for health insurance runs from ${data["insurance-enrollment-dates"]}.`);
      }
      if (data["insurance-start-date"]) {
        markdown += h3("Insurance Coverage Period");
        markdown += paragraph(`New insurance coverage begins ${data["insurance-start-date"]}.`);
      }
    }
  }

  // Employee Discount
  if (data["employee-discount"]) {
    markdown += bold("Employee Discount");
    markdown += paragraph(data["employee-discount"]);
  }

  // Holiday Information
  markdown += bold("Holiday Information");
  if (data["closed-holidays"]) {
    markdown += paragraph(data["closed-holidays"]);
  }
  if (data["offers-floating-holiday"]) {
    markdown += bold("Floating Holiday Policy");
    if (data["floating-holiday-params"]) {
      markdown += paragraph(data["floating-holiday-params"]);
    }
  }

  // Bereavement
  markdown += bold("Bereavement Leave");
  if (data["offers-bereavement"] && data["bereavement-policy"]) {
    markdown += paragraph(data["bereavement-policy"]);
  } else {
    markdown += paragraph("We do not currently offer paid bereavement leave.");
  }

  // Parental Leave
  markdown += bold("Parental Leave");
  if (data["offers-parental-leave"] && data["parental-leave-policy"]) {
    markdown += paragraph(data["parental-leave-policy"]);
  } else {
    markdown += paragraph("We follow standard FMLA guidelines for parental leave.");
  }

  // 401K
  markdown += bold("401(k) Plan");
  if (data["offers-401k"] && data["401k-policy"]) {
    markdown += paragraph(data["401k-policy"]);
  } else {
    markdown += paragraph("We do not currently offer a 401(k) plan.");
  }

  // Life Insurance
  markdown += bold("Life Insurance");
  if (data["offers-life-insurance"] && data["life-insurance-details"]) {
    markdown += paragraph(data["life-insurance-details"]);
  } else {
    markdown += paragraph("We do not currently offer life insurance benefits.");
  }

  // Employee Conduct And Performance Section
  markdown += h2("Employee Conduct And Performance");

  // Performance Evaluations
  markdown += h3("Performance Evaluations");
  if (data["performance-evaluations"]) {
    markdown += paragraph(data["performance-evaluations"]);
  } else {
    markdown += paragraph("Performance evaluations will be conducted annually.");
  }

  // Uniform - Back of House
  markdown += h3("Uniform - Back of the House");
  if (data["boh-uniform"]) {
    markdown += paragraph(data["boh-uniform"]);
  } else {
    markdown += paragraph("Back of the House employees are required to wear a uniform.");
  }

  // Uniform - Front of House
  markdown += h3("Uniform - Front of the House");
  if (data["foh-uniform"]) {
    markdown += paragraph(data["foh-uniform"]);
  } else {
    markdown += paragraph("Front of the House employees are required to wear a uniform.");
  }

  // Personal Hygiene
  markdown += h3("Personal Hygiene");
  markdown += paragraph(PREDEFINED_PERSONAL_HYGIENE_TEXT);

  // Call-in Policy
  markdown += h3("Call-in Policy");
  if (data["call-in-policy"]) {
    markdown += paragraph(data["call-in-policy"]);
  } else {
    markdown += paragraph("Employees must notify their supervisor or manager in advance for time off.");
  }

  // Time Off Request
  markdown += h3("Time Off Request");
  if (data["time-off-request"]) {
    markdown += paragraph(data["time-off-request"]);
  } else {
    markdown += paragraph("Employees must submit a time off request form at least two weeks in advance.");
  }

  // Drug Tests
  markdown += h3("Drug Tests");
  if (data["drug-tests"]?.length > 0) {
    markdown += paragraph(getDrugTestingText(data["drug-tests"]));
  } else {
    markdown += paragraph("No drug tests are required.");
  }

  // Substance Abuse Policy
  markdown += h3("Substance Abuse Policy");
  markdown += paragraph(SUBSTANCE_ABUSE_POLICY_TEXT);

  // Weapons and Violence Policy
  markdown += h3("Weapons and Violence Policy");
  markdown += paragraph(WEAPONS_AND_VIOLENCE_POLICY_TEXT);

  // Discipline Policy
  markdown += h3("Discipline Policy");
  markdown += paragraph(DISCIPLINE_POLICY_TEXT);

  // Grounds for Termination
  markdown += h3("Grounds for Termination");
  if (data["termination-grounds"]?.length > 0) {
    markdown += paragraph("Employees are subject to immediate termination for the following reasons:");
    data["termination-grounds"].forEach((terminationGround) => {
      markdown += `- ${terminationGround}\n`;
    });
  } else {
    markdown += paragraph("There are no grounds for immediate termination.");
  }

  // Conflict of Interest Policy
  markdown += h3("Conflict of Interest Policy");
  markdown += paragraph(CONFLICT_OF_INTEREST_POLICY_TEXT);

  // Trade Secret Confidentiality
  markdown += h3("Trade Secret Confidentiality");
  if (data["trade-secret-policy"]) {
    markdown += paragraph(data["trade-secret-policy"]);
  } else {
    markdown += paragraph(
      "Employees must not disclose proprietary or confidential information without explicit approval from management."
    );
  }

  markdown += h2("Privacy");

  // Electronic Signature Policy
  markdown += h3("Electronic Signature Policy");
  if (data["uses-electronic-signatures"]) {
    markdown += paragraph(ELECTRONIC_SIGNATURE_POLICY_TEXT);
  } else {
    markdown += paragraph("We do not currently use electronic signatures for documentation.");
  }

  // Marketing Photos and Personal Files
  markdown += h3("Marketing Photos and Personal Information");
  markdown += paragraph(PERSONAL_FILES_AND_SSN_MANAGEMENT_TEXT);

  if (data["posts-marketing-photos"]) {
    markdown += bold("Marketing Photos Consent");
    markdown += paragraph(
      "Employees will be required to sign a consent form before any photos or videos of them are used for marketing purposes. This form will detail how the images may be used, the duration of use, and the employee's right to revoke consent. Employees have the right to decline being photographed or having their images used for marketing purposes without any negative impact on their employment."
    );
  }

  markdown += h2("Technology, Internet, and Social Media");
  markdown += paragraph(TECH_INTERNET_SOCIAL_MEDIA_POLICY_TEXT);

  // Cell Phone Policy
  if (data["cell-phone-policy"]) {
    markdown += h3("Cell Phone Policy");
    markdown += paragraph(data["cell-phone-policy"]);
  }

  // Media Inquiry Policy
  markdown += h3("Media Inquiries");
  if (data["media-inquiry-policy"]) {
    markdown += paragraph(data["media-inquiry-policy"]);
  } else {
    markdown += paragraph("All media inquiries should be directed to management.");
  }

  markdown += h2("Company Property, Safety and Incident Reporting");
  markdown += paragraph(COMPANY_PROPERTY_AND_PROPERTY_DAMAGE_POLICY_TEXT);

  // Company Vehicle Use Policy
  if (data["uses-company-vehicles"]) {
    markdown += h3("Company Vehicle Use Policy");
    markdown += paragraph(COMPANY_VEHICLE_USE_POLICY_TEXT);
  }

  // Employee Vehicle Use Policy
  if (data["requires-personal-vehicles"]) {
    markdown += h3("Personal Vehicle Use Policy");
    markdown += paragraph(EMPLOYEE_VEHICLE_USE_POLICY_TEXT);
  }

  // Company Property and Property Damage Policy
  markdown += h3("Company Property and Property Damage Policy");
  markdown += paragraph(COMPANY_PROPERTY_AND_PROPERTY_DAMAGE_POLICY_TEXT);

  // Incident Reporting
  markdown += h3("Incident Reporting");
  markdown += paragraph(data["incident-reporting"]);

  // OSHA Policy
  markdown += h3("OSHA Compliance");
  markdown += paragraph(OSHA_POLICY_TEXT);

  // Alcohol Service Policy
  if (data["serves-alcohol"]) {
    markdown += h3("Alcohol Service Policy");
    markdown += paragraph(ALCOHOL_POLICY_TEXT);
  }

  // Add Family and Medical Leave Act Section
  markdown += h2("Family and Medical Leave Act");
  markdown += paragraph(FMLA_POLICY_TEXT);

  // Add Acknowledgment Section at the very end
  markdown += h2("Acknowledgment");
  markdown += paragraph(PREDEFINED_ACKNOWLEDGMENT_TEXT);

  return markdown;
};
