import { useUser } from "@clerk/clerk-react";
import { UserInfo } from "@shared/models";
import { useQuery } from "@tanstack/react-query";

import { getUserInfo } from "../services/api-service";

export function useUserInfo() {
  const { isSignedIn, isLoaded } = useUser();

  const { data, isLoading } = useQuery<UserInfo>({
    queryKey: ["userAuthId"],
    queryFn: () => getUserInfo(),
    retry: false,
    enabled: isLoaded && isSignedIn
  });

  const hasActivePayment = Boolean(data?.payment?.payment_status === "active");

  return { data, isLoading, hasActivePayment };
}
