import React from "react";

import { VStack, Text, Button, useToast } from "@chakra-ui/react";
import { FiDownload } from "react-icons/fi";

interface ResourceCardProps {
  title: string;
  description: string;
  downloadUrl: string;
  fileName: string;
}

export const ResourceCard: React.FC<ResourceCardProps> = ({ title, description, downloadUrl }) => {
  const toast = useToast();

  const handleDownload = async () => {
    try {
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      // Get the filename from the Content-Disposition header if available
      const contentDisposition = response.headers.get('content-disposition');
      let filename = title;
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch) {
          filename = filenameMatch[1];
        }
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to download file",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  };

  return (
    <VStack
      w="full"
      spacing={[2, 4]}
      p={[4, 8]}
      bg="white"
      borderRadius="xl"
      align="flex-start"
      h="full"
      border="solid"
      borderWidth={2}
      borderColor="blackAlpha.200"
    >
      <Text fontSize="xl" fontWeight="bold">
        {title}
      </Text>
      <Text textAlign="left" color="gray.600" flex={1}>
        {description}
      </Text>
      <Button
        alignSelf="flex-end"
        justifySelf="flex-end"
        color="black"
        variant="solid"
        colorScheme="brand"
        size="sm"
        onClick={handleDownload}
        mt="auto"
        leftIcon={<FiDownload />}
      >
        Download
      </Button>
    </VStack>
  );
}; 