import { Button } from "@chakra-ui/react";

import { usePurchaseMutation } from "../hooks/usePurchaseMutation";

interface PurchaseButtonProps {
  isLoading?: boolean;
  disabled?: boolean;
}

export const PurchaseButton = ({ isLoading, disabled }: PurchaseButtonProps) => {
  const { mutate: handlePurchase, isPending } = usePurchaseMutation();

  return (
    <Button
      colorScheme="brand"
      onClick={() => handlePurchase()}
      isLoading={isLoading || isPending}
      disabled={disabled}
      textColor={"black"}
      size="lg"
    >
      Purchase Access
    </Button>
  );
}; 