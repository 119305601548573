import { useUser } from "@clerk/clerk-react";
import { UserSopAnswer } from "@shared/models";
import { useQuery } from "@tanstack/react-query";

import { useErrorToast } from "./useErrorToast";
import { getSopAnswers } from "../services/api-service";

export const useSopAnswers = () => {
  const { showError } = useErrorToast();
  const { isSignedIn, isLoaded } = useUser();

  const { data, isLoading, error } = useQuery<UserSopAnswer[], Error>({
    queryKey: ["sopAnswers"],
    queryFn: getSopAnswers,
    retry: false,
    enabled: isLoaded && isSignedIn
  });

  // Only show error toast for actual errors, not for empty responses
  if (error && error.message !== "No SOP answers found") {
    showError(error, {
      title: "Failed to Load SOP Answers",
      description: "There was an error loading your SOP answers. Please try refreshing the page."
    });
  }

  return { data: data || [], isLoading, error };
};
