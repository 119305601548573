import { useEffect, useState } from "react";

import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { VStack, Heading, Text, Container, useToast, Spinner } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const [verificationStatus, setVerificationStatus] = useState<"loading" | "success" | "error">("loading");

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        const sessionId = searchParams.get("session_id");

        if (!sessionId) {
          throw new Error("Missing session ID");
        }

        // Initial delay to allow webhook processing
        await new Promise(resolve => setTimeout(resolve, 2000));

        // Try verification multiple times
        let attempts = 0;
        const maxAttempts = 5;
        const retryDelay = 2000;

        while (attempts < maxAttempts) {
          console.log(`Payment verification attempt ${attempts + 1} of ${maxAttempts}`);

          const response = await fetch("/api/verify-payment-status", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId }),
          });

          if (!response.ok) {
            if (response.status === 202) {
              // Payment is still pending, wait and retry
              attempts++;
              if (attempts < maxAttempts) {
                await new Promise(resolve => setTimeout(resolve, retryDelay));
                continue;
              }
              throw new Error("Payment verification timed out");
            }
            throw new Error("Payment verification failed");
          }

          const { status } = await response.json();

          if (status === "active") {
            setVerificationStatus("success");
            // Invalidate userInfo query to refresh hasActivePayment
            queryClient.invalidateQueries({ queryKey: ["userAuthId"] });
            toast({
              title: "Payment Successful",
              description: "Thank you for your purchase! You now have full access to the application.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });

            // Redirect to home after success
            setTimeout(() => {
              navigate("/");
            }, 5000);
            return;
          }

          // If we get here, status is not active, wait and retry
          attempts++;
          if (attempts < maxAttempts) {
            await new Promise(resolve => setTimeout(resolve, retryDelay));
          }
        }

        throw new Error("Payment verification timed out");
      } catch (error) {
        console.error("Payment verification error:", error);
        setVerificationStatus("error");
        toast({
          title: "Payment Verification Failed",
          description: "There was an issue verifying your payment. Please contact support if this persists.",
          status: "error",
          duration: null,
          isClosable: true,
        });
      }
    };

    verifyPayment();
  }, [navigate, toast, searchParams, queryClient]);

  if (verificationStatus === "loading") {
    return (
      <Container maxW="container.md" py={16}>
        <VStack spacing={8} textAlign="center">
          <Spinner size="xl" />
          <Heading size="lg">Verifying Payment...</Heading>
          <Text>Please wait while we confirm your payment.</Text>
        </VStack>
      </Container>
    );
  }

  if (verificationStatus === "error") {
    return (
      <Container maxW="container.md" py={16}>
        <VStack spacing={8} textAlign="center">
          <WarningIcon w={20} h={20} color="red.500" />
          <Heading size="xl">Payment Verification Failed</Heading>
          <Text fontSize="lg">
            We couldn't verify your payment status. If you believe this is an error,
            please contact our support team.
          </Text>
        </VStack>
      </Container>
    );
  }

  return (
    <Container maxW="container.md" py={16}>
      <VStack spacing={8} textAlign="center">
        <CheckCircleIcon w={20} h={20} color="green.500" />
        <Heading size="xl">Payment Successful!</Heading>
        <Text fontSize="lg">
          Thank you for your purchase. You now have full access to the application.
        </Text>
        <Text color="gray.500">
          You will be redirected to the homepage in a few seconds...
        </Text>
      </VStack>
    </Container>
  );
};

export default PaymentSuccessPage; 