import React from "react";

import { VStack, SimpleGrid, Text, Spinner, Center } from "@chakra-ui/react";

import { ResourceCard } from "./components/ResourceCard";
import { useResources } from "../../hooks/useResources";

const ResourcesPage: React.FC = () => {
  const { data: resources, isLoading, error } = useResources();

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center h="100vh">
        <Text color="red.500">Error loading resources. Please try again later.</Text>
      </Center>
    );
  }

  // Filter only active resources
  const activeResources = resources?.filter(resource => resource.status === "active");

  return (
    <VStack w="full" spacing={8} align="start" p={8}>
      <Text fontSize="2xl" fontWeight="bold">
        Resources
      </Text>
      <Text color="gray.600">
        Download helpful templates and guides for your restaurant operations.
      </Text>

      <SimpleGrid columns={[1, 1, 3]} spacing={[4, 8]} w="full">
        {activeResources?.map((resource) => (
          <ResourceCard
            key={resource.id}
            title={resource.display_name}
            description={resource.description}
            fileName={resource.stored_file_name}
            downloadUrl={`/api/files/${resource.id}/download`}
          />
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default ResourcesPage; 