import { useEffect } from "react";

import { useToast } from "@chakra-ui/react";
import { Navigate, useLocation } from "react-router-dom";

import { useUserInfo } from "../../hooks/useUserInfo";

interface RequirePaymentProps {
  children: React.ReactNode;
}

const RequirePayment: React.FC<RequirePaymentProps> = ({ children }) => {
  const { hasActivePayment, isLoading } = useUserInfo();
  const location = useLocation();
  const toast = useToast();

  useEffect(() => {
    if (!isLoading && !hasActivePayment && location.pathname !== "/") {
      toast({
        title: "Subscription Required",
        description: "Please purchase a subscription to access this feature.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    }
  }, [hasActivePayment, isLoading, location.pathname, toast]);

  if (isLoading) {
    return null;
  }

  if (!hasActivePayment && location.pathname !== "/") {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default RequirePayment; 