/**
 * @note
 * for hook alternative of route element composition:
 * - https://reactrouter.com/docs/en/v6/upgrading/v5#use-useroutes-instead-of-react-router-config
 * - https://reactrouter.com/docs/en/v6/examples/route-objects
 *
 * might need to take notes on:
 * - https://reactrouter.com/docs/en/v6/upgrading/v5#note-on-link-to-values
 */

import { Suspense } from "react";

import { Routes, Route } from "react-router-dom";

import { routes } from "./routes";
import Layout from "../layout/Layout";
import SignInPage from "../pages/SignInPage";
import SignUpPage from "../pages/SignUpPage";
import SopBuilderPage from "../pages/sop-builder/SopBuilderPage";
import SopPage from "../pages/sop-builder/SopPage";

import RequireAuth from "~/components/auth/RequireAuth";
import RequirePayment from "~/components/auth/RequirePayment";
import Page404 from "~/pages/404/404Page";

const Routings = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/signin" element={<SignInPage />} key={"/signin"} />
        <Route path="/signup" element={<SignUpPage />} key={"/signup"} />

        {/* All authenticated routes */}
        {routes.map(({ element, bodyPadding, bgColor, noSubscriptionNeeded, ...routeProps }) => (
          <Route
            element={
              <RequireAuth redirectTo={`/signin?redirectTo=${window.location.pathname}`}>
                {noSubscriptionNeeded ? (
                  <Layout bodyPadding={bodyPadding} bgColor={bgColor ?? "#F9F8F8"}>
                    {element}
                  </Layout>
                ) : (
                  <RequirePayment>
                    <Layout bodyPadding={bodyPadding} bgColor={bgColor ?? "#F9F8F8"}>
                      {element}
                    </Layout>
                  </RequirePayment>
                )}
              </RequireAuth>
            }
            {...routeProps}
            key={`route-${routeProps.path}`}
          />
        ))}

        {/* SOP Builder routes */}
        <Route
          path="sop-builder/*"
          element={
            <RequireAuth redirectTo={`/signin?redirectTo=${window.location.pathname}`}>
              <RequirePayment>
                <Layout>
                  <SopBuilderPage />
                </Layout>
              </RequirePayment>
            </RequireAuth>
          }
        >
          <Route index element={<SopPage />} />
          <Route path=":section/:page" element={<SopPage />} />
        </Route>

        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
};

export default Routings;
