import React, { useEffect, useState } from "react";

import { VStack, Box, Text, Image, SimpleGrid, Spinner, HStack, Button, Heading } from "@chakra-ui/react";
import { MdPersonSearch } from "react-icons/md";
import { PiGraduationCapFill } from "react-icons/pi";
import { RiFilePaper2Line, RiBookLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { CompletedArtifactCard } from "./components/CompletedArtifactCard";
import { ToolCard } from "./components/ToolCard";
import { GeneratedJobDescriptionWithInfo } from "../../../../../shared/job-description-models";
import { MarketingFeatureCard } from "../../components/MarketingFeatureCard";
import { PurchaseButton } from "../../components/PurchaseButton";
import { useJobDescription } from "../../hooks/useJobDescription";
import { useSopAnswers } from "../../hooks/useSopAnswers";
import { useUserInfo } from "../../hooks/useUserInfo";

type Artifact = GeneratedJobDescriptionWithInfo;

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { data: user, hasActivePayment, isLoading: isLoadingUser } = useUserInfo();
  const { data: sopAnswers, isLoading: isLoadingSop } = useSopAnswers();
  const { completedJobDescriptions, isLoadingCompletedJobDescriptions } = useJobDescription();
  const hasExistingSopAnswers = sopAnswers && Object.keys(sopAnswers).length > 0;
  const [showAllCompletedArtifacts, setShowAllCompletedArtifacts] = useState(false);
  const [showAllTools, setShowAllTools] = useState(false);
  const [completedArtifacts, setCompletedArtifacts] = useState<Artifact[]>([]);

  useEffect(() => {
    if (!completedJobDescriptions) return;
    const artifacts = completedJobDescriptions.sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    setCompletedArtifacts(artifacts);
  }, [completedJobDescriptions]);

  // Show loading state if any of the critical data is still loading
  const isLoading = isLoadingUser || isLoadingSop || isLoadingCompletedJobDescriptions;
  if (isLoading || !user) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="lg" />
      </Box>
    );
  }

  const tools = [
    {
      title: "SOP Builder",
      description: "Create your Standard Operating Procedures in minutes, not hours, with our AI assisted process.",
      icon: <RiFilePaper2Line size={23} />,
      route: "/sop-builder",
      actionText: hasExistingSopAnswers ? "Continue" : "Get Started"
    },
    {
      title: "Job Description Builder",
      description: "Create publish ready job descriptions for exactly what you need.",
      icon: <MdPersonSearch size={25} />,
      route: "/job-builder"
    },
    {
      title: "Handbook Builder",
      description: "Generate a comprehensive employee handbook tailored to your restaurant's needs.",
      icon: <RiBookLine size={24} />,
      route: "/handbook-builder"
    },
    {
      title: "Courses",
      description: "View our courses and learn the skills you need to succeed.",
      icon: <PiGraduationCapFill size={24} />,
      actionText: "View Courses",
      route: "https://up-or-on-the-rocks-hospitality.teachable.com/",
      isExternalLink: true
    }
  ];

  const marketingFeatures = [
    {
      title: "SOP Builder",
      description: "Create comprehensive Standard Operating Procedures in minutes, not hours.",
      icon: RiFilePaper2Line,
      benefits: [
        "AI-powered procedure generation",
        "Customizable templates",
        "Industry best practices",
        "Export to PDF"
      ]
    },
    {
      title: "Job Description Creator",
      description: "Generate professional job descriptions tailored to your restaurant's needs.",
      icon: MdPersonSearch,
      benefits: [
        "Role-specific requirements",
        "ADA compliant language",
        "Customizable responsibilities",
        "Ready to publish format"
      ]
    },
    {
      title: "Handbook Builder",
      description: "Build a complete employee handbook that sets clear expectations and policies.",
      icon: RiBookLine,
      benefits: [
        "Legal compliance built-in",
        "Industry-specific policies",
        "Easy customization",
        "Professional formatting"
      ]
    }
  ];

  return (
    <VStack w="full" spacing={0} p={0} overflow="hidden" m={0} h="100%">
      <VStack bg="black" w="full" minH={["250px", "400px"]} p={[4, 6]} borderTopRadius={25} justify="center">
        <Image src="/images/rocks-logo.png" alt="Up or On The Rocks Hospitality" maxW={["200px", "300px"]} />
      </VStack>

      <VStack
        w="full"
        border="solid"
        borderWidth={3}
        borderColor="brand.500"
        borderTopWidth={[0, 0]}
        borderBottomRadius={25}
        p={[2, 6]}
        h="100%"
      >
        {user && !hasActivePayment && (
          <Box textAlign="center" py={8}>
            <VStack spacing={8}>
              <VStack spacing={4}>
                <Heading textAlign="center" mb={4}>Welcome to Up or On The Rocks</Heading>
                <Heading size="md">Get Full Access</Heading>
                <Text fontSize="lg" maxW="2xl">
                  Transform your restaurant operations with our powerful suite of tools.
                </Text>
                <PurchaseButton
                  disabled={user.payment?.payment_status === "inactive"}
                />
              </VStack>

              <SimpleGrid columns={[1, 1, 3]} spacing={6} w="full" pt={4}>
                {marketingFeatures.map((feature) => (
                  <MarketingFeatureCard
                    key={feature.title}
                    {...feature}
                  />
                ))}
              </SimpleGrid>
            </VStack>
          </Box>
        )}

        {user && hasActivePayment && (
          <VStack w="full" spacing={[4, 8]} align="start" maxW={"container.xl"} h="100%" pt={[0, 10]}>
            <HStack w="full" justify="space-between" align="center">
              <Text fontSize="2xl" fontWeight="bold">
                Helpful Tools
              </Text>
              <Button variant="link" colorScheme="brand" size="sm" onClick={() => setShowAllTools(!showAllTools)}>
                {showAllTools ? "View Less" : "View All"}
              </Button>
            </HStack>

            <SimpleGrid columns={[1, 1, 3]} spacing={[2, 8]} w="full">
              {tools.slice(0, showAllTools ? undefined : 3).map((tool) => (
                <ToolCard
                  key={tool.title}
                  title={tool.title}
                  description={tool.description}
                  icon={tool.icon}
                  route={tool.route}
                  actionText={tool.actionText}
                  isExternalLink={tool.isExternalLink}
                />
              ))}
            </SimpleGrid>

            {completedJobDescriptions && completedJobDescriptions.length > 0 && (
              <>
                <HStack w="full" justify="space-between" align="center" mt={4}>
                  <Text fontSize="2xl" fontWeight="bold">
                    Recently Completed
                  </Text>
                  {completedArtifacts.length > 3 && (
                    <Button
                      variant="link"
                      colorScheme="brand"
                      size="sm"
                      onClick={() => setShowAllCompletedArtifacts(!showAllCompletedArtifacts)}
                    >
                      {showAllCompletedArtifacts ? "View Less" : "View All"}
                    </Button>
                  )}
                </HStack>

                <SimpleGrid columns={[1, 1, 3]} spacing={[2, 8]} w="full">
                  {completedArtifacts.slice(0, showAllCompletedArtifacts ? undefined : 3).map((artifact) => (
                    <CompletedArtifactCard
                      key={artifact.id}
                      title={`${artifact.jobDescriptionInfo.position} Job Description`}
                      createdAt={artifact.createdAt}
                      buttonText="View Job Description"
                      handleClick={() => navigate(`/job-builder/${artifact.jobDescriptionInfo.id}`)}
                    />
                  ))}
                </SimpleGrid>
              </>
            )}
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

export default HomePage;
