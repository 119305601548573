import { useUser } from "@clerk/clerk-react";
import {
  JobDescriptionInfo,
  CreateJobDescriptionRequest,
  SaveGeneratedJobDescriptionRequest,
  GeneratedJobDescriptionWithInfo
} from "@shared/job-description-models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useErrorToast } from "./useErrorToast";
import { useUserInfo } from "./useUserInfo";
import { jobDescriptionService } from "../services/job-description-service";

export const useJobDescription = (jobDescriptionId?: number) => {
  const queryClient = useQueryClient();
  const { data: userData, isLoading: isLoadingUser } = useUserInfo();
  const { isSignedIn, isLoaded } = useUser();
  const { showError } = useErrorToast();

  const { data: completedJobDescriptions, isLoading: isLoadingCompletedJobDescriptions } = useQuery<
    GeneratedJobDescriptionWithInfo[]
  >({
    queryKey: ["allJobDescriptions", userData?.id],
    queryFn: () => {
      return jobDescriptionService.getAllJobDescriptions();
    },
    enabled: isLoaded && isSignedIn && !isLoadingUser && !!userData?.id,
    staleTime: 0,
    retry: 2
  });

  const {
    data: previousJobDescription,
    isLoading: isLoadingPreviousJobDescription,
    error: previousJobDescriptionError
  } = useQuery<JobDescriptionInfo | null>({
    queryKey: ["previousJobDescription", userData?.id],
    queryFn: () => jobDescriptionService.getPreviousJobDescription(),
    enabled: isLoaded && isSignedIn && !isLoadingUser && !!userData?.id && !jobDescriptionId
  });

  const { data: generatedJobDescription, isLoading: isLoadingGeneratedJobDescription } = useQuery({
    queryKey: ["generatedJobDescription", jobDescriptionId, userData?.id],
    queryFn: () => jobDescriptionService.getGeneratedJobDescription(jobDescriptionId!),
    enabled: isLoaded && isSignedIn && !isLoadingUser && !!userData?.id && !!jobDescriptionId
  });

  const { mutateAsync: createJobDescription, isPending: isCreatingJobDescription } = useMutation({
    mutationFn: (data: CreateJobDescriptionRequest) => jobDescriptionService.createJobDescription(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["previousJobDescription", userData?.id] });
    },
    onError: (error) => {
      showError(error, {
        title: "Failed to Create Job Description",
        description: "There was an error creating your job description. Please try again."
      });
    }
  });

  const { mutateAsync: saveGeneratedJobDescription, isPending: isSavingGeneratedJobDescription } = useMutation({
    mutationFn: (data: SaveGeneratedJobDescriptionRequest) => jobDescriptionService.saveGeneratedJobDescription(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["previousJobDescription", userData?.id] });
      queryClient.invalidateQueries({ queryKey: ["generatedJobDescription", jobDescriptionId, userData?.id] });
      queryClient.invalidateQueries({ queryKey: ["allJobDescriptions", userData?.id] });
    },
    onError: (error) => {
      showError(error, {
        title: "Failed to Save Job Description",
        description: "There was an error saving your job description. Please try again."
      });
    }
  });

  return {
    previousJobDescription,
    isLoadingPreviousJobDescription,
    previousJobDescriptionError,
    generatedJobDescription,
    isLoadingGeneratedJobDescription,
    createJobDescription,
    isCreatingJobDescription,
    saveGeneratedJobDescription,
    isSavingGeneratedJobDescription,
    completedJobDescriptions,
    isLoadingCompletedJobDescriptions
  };
};
