import React, { useState } from "react";


import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  VStack,
  Button,
  Code,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
  Box,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton
} from "@chakra-ui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Navigate } from "react-router-dom";

import { useAdminDeleteAllSop } from "../../hooks/useAdminDeleteAllSop";
import { useUserInfo } from "../../hooks/useUserInfo";

interface DocumentFormData {
  displayName: string;
  description: string;
  category: string;
  file: File | null;
  isActive: boolean;
}

interface FileRecord {
  id: number;
  display_name: string;
  description: string;
  category: string;
  stored_file_name: string;
  status: string;
  created_at: string;
}

interface EditFormData {
  id: number;
  display_name: string;
  description: string;
  category: string;
  isActive: boolean;
}

const AdminPage: React.FC = () => {
  const { data: userInfo } = useUserInfo();
  const adminDeleteAllSop = useAdminDeleteAllSop();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [formData, setFormData] = useState<DocumentFormData>({
    displayName: "",
    description: "",
    category: "SOP",
    file: null,
    isActive: true
  });

  const [editModalData, setEditModalData] = useState<EditFormData | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  // Query to fetch all files
  const { data: files, isLoading: isLoadingFiles } = useQuery({
    queryKey: ["admin-files"],
    queryFn: async () => {
      const response = await fetch("/api/files/all");
      if (!response.ok) {
        throw new Error("Failed to fetch files");
      }
      return response.json() as Promise<FileRecord[]>;
    }
  });

  // Mutation to update file record
  const updateFileMutation = useMutation({
    mutationFn: async (data: EditFormData) => {
      const updateData = {
        display_name: data.display_name,
        description: data.description,
        category: data.category,
        status: data.isActive ? "active" : "inactive"
      };
      const response = await fetch(`/api/files/${data.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(updateData)
      });
      if (!response.ok) {
        throw new Error("Failed to update file");
      }
      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin-files"] });
      toast({
        title: "Success",
        description: "File updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to update file",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  });

  // Mutation to delete file record
  const deleteFileMutation = useMutation({
    mutationFn: async (id: number) => {
      const response = await fetch(`/api/files/${id}`, {
        method: "DELETE"
      });
      if (!response.ok) {
        throw new Error("Failed to delete file");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin-files"] });
      toast({
        title: "Success",
        description: "File deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to delete file",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  });

  if (!userInfo?.isAdmin) {
    return <Navigate to="/" />;
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData(prev => ({
        ...prev,
        file: e.target.files![0]
      }));
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === "isActive" ? value === "true" : value
    }));
  };

  const handleEditModalInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setEditModalData(prev => {
      if (!prev) return null;
      const newValue = name === "isActive" ? value === "true" : value;
      const updatedData = {
        ...prev,
        [name]: newValue
      };
      return updatedData;
    });
  };

  const handleDelete = (id: number) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      deleteFileMutation.mutate(id);
    }
  };

  const handleEditSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (editModalData) {
      updateFileMutation.mutate(editModalData);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.file) {
      toast({
        title: "Error",
        description: "Please select a file to upload",
        status: "error",
        duration: 3000,
        isClosable: true
      });
      return;
    }

    setIsUploading(true);
    try {
      // Create form data to send file and metadata
      const formDataToSend = new FormData();
      formDataToSend.append('file', formData.file);
      formDataToSend.append('displayName', formData.displayName);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('category', formData.category);
      formDataToSend.append('isActive', String(formData.isActive));

      // Send everything to the backend in a single request
      const response = await axios.post("/api/files/upload", formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to upload file");
      }

      toast({
        title: "Success",
        description: "Document uploaded successfully",
        status: "success",
        duration: 3000,
        isClosable: true
      });

      // Refresh the files list
      queryClient.invalidateQueries({ queryKey: ["admin-files"] });

      // Reset form
      setFormData({
        displayName: "",
        description: "",
        category: "SOP",
        file: null,
        isActive: true
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to upload document",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <VStack w="full" spacing={8} align="start" p={8}>
      <Text fontSize="2xl" fontWeight="bold">
        Admin Dashboard
      </Text>

      <VStack align="start" spacing={4} w="full">
        <Text fontSize="xl" fontWeight="semibold">
          User Information
        </Text>
        {userInfo && (
          <pre>
            <Code>{JSON.stringify(userInfo, null, 2)}</Code>
          </pre>
        )}
      </VStack>

      <Divider />

      <VStack align="start" spacing={4} w="full" maxW="600px">
        <Text fontSize="xl" fontWeight="semibold">
          Upload New Document
        </Text>
        <Box as="form" onSubmit={handleSubmit} w="full">
          <VStack spacing={4} align="start">
            <FormControl isRequired>
              <FormLabel>Display Name</FormLabel>
              <Input
                name="displayName"
                value={formData.displayName}
                onChange={handleInputChange}
                placeholder="Enter document title"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Enter document description"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Category</FormLabel>
              <Select name="category" value={formData.category} onChange={handleInputChange}>
                <option value="SOP">Standard Operating Procedure</option>
                <option value="TRAINING">Training Material</option>
                <option value="POLICY">Policy Document</option>
                <option value="OTHER">Other</option>
              </Select>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>File</FormLabel>
              <Input type="file" onChange={handleFileChange} accept=".pdf,.doc,.docx" />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Status</FormLabel>
              <Select name="isActive" value={formData.isActive ? "true" : "false"} onChange={handleInputChange}>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </Select>
            </FormControl>

            <Button
              type="submit"
              colorScheme="blue"
              isLoading={isUploading}
              loadingText="Uploading..."
              w="full"
            >
              Upload Document
            </Button>
          </VStack>
        </Box>
      </VStack>

      <Divider />

      <VStack align="start" spacing={4} w="full">
        <Text fontSize="xl" fontWeight="semibold">
          All Documents
        </Text>
        {isLoadingFiles ? (
          <Text>Loading files...</Text>
        ) : (
          <Box overflowX="auto" w="full">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Display Name</Th>
                  <Th>Description</Th>
                  <Th>Category</Th>
                  <Th>Status</Th>
                  <Th>Created At</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {files?.map((file) => (
                  <Tr key={file.id}>
                    <Td>{file.display_name}</Td>
                    <Td>{file.description}</Td>
                    <Td>{file.category}</Td>
                    <Td>{file.status === "active" ? "Active" : "Inactive"}</Td>
                    <Td>{new Date(file.created_at).toLocaleString()}</Td>
                    <Td>
                      <IconButton
                        aria-label="Edit file"
                        icon={<EditIcon />}
                        size="sm"
                        mr={2}
                        onClick={() => {
                          const editData: EditFormData = {
                            id: file.id,
                            display_name: file.display_name,
                            description: file.description,
                            category: file.category,
                            isActive: file.status === "active"
                          };
                          setEditModalData(editData);
                          onOpen();
                        }}
                      />
                      <IconButton
                        aria-label="Delete file"
                        icon={<DeleteIcon />}
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDelete(file.id)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </VStack>

      <Divider />

      <VStack align="start" spacing={4} w="full">
        <Text fontSize="xl" fontWeight="semibold">
          Danger Zone
        </Text>
        <Text fontSize="sm" fontWeight="semibold">
          This button will delete all of your SOP answers, allowing you to start fresh.
        </Text>
        <Button
          colorScheme="red"
          onClick={() => adminDeleteAllSop.mutate()}
          isLoading={adminDeleteAllSop.isPending}
        >
          Delete All SOP Answers (Admin Only)
        </Button>
      </VStack>

      {/* Edit Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Document</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleEditSubmit}>
            <ModalBody>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Display Name</FormLabel>
                  <Input
                    name="display_name"
                    value={editModalData?.display_name || ""}
                    onChange={handleEditModalInputChange}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    name="description"
                    value={editModalData?.description || ""}
                    onChange={handleEditModalInputChange}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Category</FormLabel>
                  <Select
                    name="category"
                    value={editModalData?.category || ""}
                    onChange={handleEditModalInputChange}
                  >
                    <option value="SOP">Standard Operating Procedure</option>
                    <option value="TRAINING">Training Material</option>
                    <option value="POLICY">Policy Document</option>
                    <option value="OTHER">Other</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Select
                    name="isActive"
                    value={String(editModalData?.isActive)}
                    onChange={handleEditModalInputChange}
                  >
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </Select>
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={updateFileMutation.isPending}
              >
                Save Changes
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default AdminPage; 